<template>
  <div>new user</div>
</template>

<script>
export default {
  name: "NewUser"
};
</script>

<style></style>
